@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, div#root {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}



main {
  /*background-color: white;*/
  /*margin-top: 60px;*/
  width: auto;
  height: auto;
  /*max-height: 500px;*/
  display: flex;
  /*justify-content: flex-start;*/
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #400CCC;
  padding-right: 79px;
  padding-left: 40px;
}



.issue {
  margin: 10px;
  padding: 20px;
}

.msg-banner {
  background-color: rgba(63, 6, 210, 0.14);
  /*width: 100%;*/
  padding: 10px 20px 5px 20px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 12%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}


.dashboard-grid__column{
  border: lightslategrey solid 1px;
}
.dashboard-grid__header{
  padding: 5px;
  text-align: center;
  border: lightslategrey solid 1px;
}

.issue-sidebar .label {
  font-weight: bold;
}
.issue-sidebar__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.button-row-medium {
  display: flex;
  justify-content: flex-start;
  gap:10px;
}
.button-row-tight {
  display: flex;
  justify-content: flex-start;
  gap:5px;
}

.droppable-column {
  /*background-color: rgba(129, 129, 129, 0.04);*/
  flex-grow: 1;
}


